<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs
            background-color="#002441"
            active-class="white black--text"
            height="60"
            dark
          >

            <v-tab>Apps Users</v-tab>
            <v-tab>Web Users</v-tab>

            <!-- THIS TAB IS FOR APP USERS -->
            <v-tab-item>
              <v-card class="ml-10 mr-10 mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="appUsers"
                :loading="loading"
                loading-text="Loading... Please wait"
                class="elevation-1"
                style="background: #f5f5f5"
                :server-items-length="totalItems1"
                 hide-default-footer
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    v-if="item.role !== 'manager'"
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>
                <div class="d-flex justify-content-center mt-5">
                  <!-- <v-col cols="3" md="3"> -->
                  <div class="col-4">
                      Total {{ totalItems1 }} records
                  </div>
                  <!-- </v-col> -->
                  <!-- <v-col cols="3" md="3"> -->
                  <div class="col-4">
                      <v-select dense outlined hide-details v-model="perPage1" label="Items per page" @change="handlePageSizeChange" :items="pageSizes1" >
                      </v-select>                                                
                  </div>
                  <!-- </v-col> -->
                  <!-- <v-col cols="6" md="3" > -->
                  <div class="col-4">
                      <v-pagination v-model="currentPage1" :length="totalPages1" total-visible="7" @input="handlePageChange">
                      </v-pagination>
                  </div>
                  <!-- </v-col> -->
                </div>
            </v-card>
            </v-tab-item>
            <!-- THIS TAB IS FOR APP USERS -->

            <!-- THIS TAB IS FOR WEB USERS -->
             <v-tab-item>
              <v-card class="ml-10 mr-10 mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="webUsers"
                :loading="loading"
                loading-text="Loading... Please wait"
                class="elevation-1"
                style="background: #f5f5f5"
                :server-items-length="totalItems2"
                hide-default-footer
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    v-if="item.role !== 'manager'"
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>

              <div class="d-flex justify-content-center mt-5">
                <!-- <v-col cols="3" md="3"> -->
                <div class="col-4">
                    Total {{ totalItems2 }} records
                </div>
                <!-- </v-col> -->
                <!-- <v-col cols="3" md="3"> -->
                <div class="col-4">
                    <v-select dense outlined hide-details v-model="perPage2" label="Items per page" @change="handlePageSizeChange1" :items="pageSizes2" >
                    </v-select>                                                
                </div>
                <!-- </v-col> -->
                <!-- <v-col cols="6" md="3" > -->
                <div class="col-4">
                    <v-pagination v-model="currentPage2" :length="totalPages2" total-visible="7" @input="handlePageChange1">
                    </v-pagination>
                </div>
                <!-- </v-col> -->
              </div>
            </v-card>
            </v-tab-item>
            <!-- THIS TAB IS FOR WEB USERS -->

          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    // date: new Date().toISOString().substr(0, 10),
    loading: true,
    dialog: false,
    snackbar: false,
    perPage1: '10',
    currentPage1: 1,
    totalItems1: 0,
    totalPages1: 0,
    pageSizes1: ['10', '30', '50', '100', 'All'],
    perPage2: '10',
    currentPage2: 1,
    totalItems2: 0,
    totalPages2: 0,
    pageSizes2: ['10', '30', '50', '100', 'All'],
    usercreatedialog: false,
    userCreateFormModel: false,
    Editusercreatedialog: false,
    warehouses: [],
    shops: [],
    color: "",
    text: "",
    select_val: ["Staff", "Admin", "Seller"],
    headers: [
      { text: "Name", value: "username" },
      { text: "Email", value: "email" },
      { text: "Contact Number", value: "phone_number" },
      { text: "Device Name", value: "device_name" },
      { text: "Action", value: "actions" },
    ],
    appUsers: [],
    webUsers: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  methods: {

    handlePageSizeChange(event) {
      this.perPage1 = (event);
      this.loading = true;
      this. getAppUsers()
    },

    handlePageChange(value) {
      this.currentPage1 = value;
      this.loading = true;
      this. getAppUsers()
      window.scrollTo(0, 0);
    },
    handlePageSizeChange1(event) {
      this.perPage1 = (event);
      this.loading = true;
      this.getWebUsers();
    },

    handlePageChange1(value) {
      this.currentPage1 = value;
      this.loading = true;
      this.getWebUsers();
      window.scrollTo(0, 0);
    },
    getAppUsers() {
      let perPage ;
      if (this.perPage1 === 'All'){
        perPage = this.totalItems1;
      }
      else
      {
        perPage = this.perPage1;
      }
      this.loading = true;
     
      axios
        .get(`user/show_apps_user/?page=${this.currentPage1}&size=${perPage}`)
        .then((response) => {
          this.appUsers = response.data.results;
          this.totalItems1 = response.data.count;
          this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
          this.loading = false;
          axios
            .get("productdetails/warehouses/")
            .then((response) => {
              this.warehouses = response.data.data;

              axios
                .get("productdetails/shops/")
                .then((response) => {
                  this.shops = response.data.data;
                  this.loading = false;
                })
                .catch((err) => {
                  this.loading = false;
                });
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getWebUsers() {

      let perPage1 ;
      if (this.perPage2 === 'All'){
        perPage1 = this.totalItems2;
      }
      else
      {
        perPage1 = this.perPage2;
      }
      this.loading = true;
      axios
        .get(`user/show_web_user/?page=${this.currentPage2}&size=${perPage1}`)
        .then((response) => {
          this.webUsers = response.data.results;
          this.totalItems2 = response.data.count;
          this.totalPages2 = parseInt(this.totalItems2 / this.perPage2);
          this.loading = false;
          axios
            .get("productdetails/warehouses/")
            .then((response) => {
              this.warehouses = response.data.data;

              axios
                .get("productdetails/shops/")
                .then((response) => {
                  this.shops = response.data.data;
                  this.loading = false;
                })
                .catch((err) => {
                  this.loading = false;
                });
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getAppUsers();
    this.getWebUsers();
  },
};
</script>


<style>
.v-sheet--offset {
  top: -10px;
  position: relative;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

